import React from 'react'

const ComingSoon = () => {
  return (
    <div className='flex flex-col justify-center items-center h-[100vh]'>
        <p className='text-6xl font-extrabold'>Coming Soon</p>
        <p className='text-3xl hover:underline mt-10'><a href="mailto:alcantaradevelopment@gmail.com">Contact Me</a></p>
    </div>
  )
}

export default ComingSoon