import './App.css';
import ComingSoon from './Pages/ComingSoon';

function App() {
  return (
    <div>
      <ComingSoon />
    </div>
  );
}

export default App;
